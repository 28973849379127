import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import styles from "./Policy.module.scss";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function PublicContract() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font40">Публичный договор</h1>
          <HeaderP className="font13 semiBold">
            ДОГОВОР ОБ ОКАЗАНИИ УСЛУГ В ОЗДОРОВИТЕЛЬНОМ ЦЕНТРЕ «МИР КУНГ-ФУ»
          </HeaderP>

           <div className={styles.contentContainer}>

           <p className={styles.contentPart}>
           г.Минск от 01 декабря 2023 г.
           </p>
           <p className={styles.contentPart}>
           Настоящий договор об оказании услуг ООО «Хонг-Гиа» под названием «Мир кунг-фу», именуемый далее «МКФ», в лице директора Безрукавого Евгения Владимировича, действующего на основании Устава, с одной стороны, в отношении потребителя услуг _________________________________________________________________________________________________________________, а также членов его семьи ________________________________________________________________________________________ (далее именуемый «КЛИЕНТОМ»), с другой стороны.
           </p>

          <p className={styles.contentHeader}>
          1. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА
          </p>

           <p className={styles.contentPart}>
           1.1. Договор является соглашением между МКФ и КЛИЕНТОМ, желающим получать услугу по программам «Академия КФ», «Школа КФ» и «ОФК КФ»  с принятием всех прав и обязанностей двумя сторонами данного Договора.
           1.2. Условия Договора считаются принятыми КЛИЕНТОМ, если он подписал данный Договор или приобрёл любой абонемент.
           1.3. Договор вступает в силу с момента получения оплаты ОЦ по любой из программ.
           1.4. Договор и Приложения к нему являются официальными документами.
           1.5. Акцепт (принятие) оферты означает полное и безоговорочное принятие КЛИЕНТОМ всех условий Договора без каких-либо изъятий и/или ограничений.
            </p>

           <p className={styles.contentHeader}>
           2. ПРЕДМЕТ ДОГОВОРА
           </p>

           <p className={styles.contentPart}>
          2.1. КЛИЕНТ поручает, а МКФ принимает на себя обязательство по оказанию КЛИЕНТУ услуг, предусмотренных программами:
          • «Академия КФ» (в дальнейшем АКФ)
          • «Школа КФ» (в дальнейшем ШКФ)
          • «Оздоровление КФ» (в дальнейшем ОКФ)
          2.2. КЛИЕНТ обязуется оплатить абонемент в размере и порядке, предусмотренном настоящим договором, и соблюдать Правила МКФ. Программы АКФ, ШКФ и ОКФ выставлены на официальном сайте МКФ - kung-fu.by.
          2.3. Под абонементом понимается именное право КЛИЕНТА на получение услуг МКФ в соответствии с условиями настоящего договора и внесенных средств, согласно прайсу.
          2.4. Разделения по возрастам в договоре оказания услуг не существует: и дети, и взрослые могут посещать все программы - за детей до 16 лет договор подписывают родители или опекуны с предоставлением соответствующего документа к данному договору.
          </p>

           <p className={styles.contentHeader}>
          3. ПРАВА И ОБЯЗАННОСТИ СТОРОН
           </p>
           <p className={styles.contentPart}>
           3.1. КЛИЕНТ имеет право:
           3.1.1. Ознакомиться с программой и расписанием и выкупить абонемент на выбранную программу, согласно тарифу.
           3.1.2. Получать услугу по выбранной программе, согласно выкупленному периоду.
           3.1.3. Посещать МКФ в часы его работы, согласно бронированному времени занятий или указанному в абонементе.
           3.1.4. Заказать и приобрести специальную форму для занятий кунг-фу.
           3.1.5. Отработать урок в другой группе такой же программы при невозможности посещения выкупленных занятий по действующему абонементу по уважительным причинам, признанными нами таковыми (болезнь, командировка).
           3.1.6. Получить видеоматериалы по изучаемой программе при покупке абонемента АКФ после оплаты месяца обучения или всего курса полностью.
           3.1.7. Выкупить в «Магазине МКФ» любую заявленную видеопрограмму. Доступ осуществляется бессрочно через ссылку.
           3.1.8. Проходить обучение персонально с сертифицированным специалистом МКФ.
           3.1.9. Сдать тест для перехода в следующий класс по программе ШКФ. Сроки действия программы и время сдачи тестов - на официальном сайте МКФ.
           3.1.10. Приостановить свое обучение без объяснения причины в ОКФ.
           3.1.11. Приостановить обучение в АКФ и ШКФ только после окончания заявленного семестра своего класса и уйти в академический отпуск по заявлению. В случае прекращения занятий без письменного сообщения администрации МКФ у КЛИЕНТА аннулируются все его уровни, звания, доступы к информации и видео-урокам (кроме выкупленных через Магазин), деньги за не посещенные занятия не возвращаются.
           3.1.12. Вернуться в МКФ. Для продолжения обучения и определения группы КЛИЕНТУ, кроме ОФК, необходимо сдать тест.
           3.1.13. Использовать действующую программу лояльности.
           3.1.14. Участвовать в любых семинарах, вебинарах, выездных лагерях, ретритах и т.д., подав предварительно заявку.
           3.1.15. Получить видео-уроки по выкупленному курсу АКФ.
           3.1.16. Посетить одно пробное занятие по курсу АКФ и потом принять решение: выкупить весь заявленный курс или оплатить пробное занятие согласно тарифу МКФ. В течение курса КЛИЕНТ может выйти из программы, оплатив только те уроки, что посетил, по специальному тарифу согласно «Правил приобретения, изменения, переноса и закрытия абонемента», описанных в Приложении к данному договору, выставленному на нашем официальном сайте.
           </p>

           <p className={styles.contentPart}>
           3.2. КЛИЕНТ обязан:
           3.2.1. Своевременно оплатить стоимость абонемента, согласно разделу 4 настоящего Договора, не позднее 5-го числа текущего месяца. Возможно согласование иной дата оплаты, согласно дополнительному соглашению. Срок действия абонемента - с 01 по 30/31 число.
           3.2.2. При входе в программу любого класса ШКФ необходимо пройти её в полном объёме и в установленные сроки, а также сдать тест для перехода в следующий класс. В случае пропуска занятий или отставания от изучаемой программы, КЛИЕНТУ необходимо догнать группу изучаемой им программы, для этого необходимо выкупить видео-уроки или пройти персональные занятия. В случае невозможности сделать это, он переходит в группу классом ниже.
           3.2.3. При входе в программу любого курса АКФ необходимо оплатить его полностью одним платежом или в установленном порядке, если такой предлагается. Желающие иметь сертификат сдают экзамен по данному курсу. В случае приостановки посещения курса АКФ КЛИЕНТОМ он все равно обязан выплатить полную его стоимость и получить от МКФ видеозапись выкупленной программы.
           3.2.4. Приобрести пакет студента ШКФ: удостоверение, зачетная книжка, квалификационный пояс.
           3.2.5. Для прохождения теста ШКФ или экзамена АКФ необходимо произвести его оплату согласно действующему прайсу, установленному в МКФ. Вся история прохождения обучения по классам и семинарам отмечается в зачетных книжках. При необходимости студенту выдается сертификат о пройденном классе/курсе.
           3.2.6. Перейдя в четвертый класс программы и выше по ШКФ, оплачивать занятия по тарифу «Универсальный» с возможностью свободного посещения любых групп и получения видеоматериалов по изучаемой программе с персональной поддержкой куратора группы.
           3.2.7. Не передавать полученные знания третьим лицам без письменного разрешения администрации МКФ. В противном случае, МКФ имеет право востребовать штраф за распространение данных материалов и нарушение авторских прав на данные программы в размере 1000 (тысячи) базовых величин РБ на день обнаружения данного факта.
           3.2.8. Не приступать к занятиям в плохом самочувствии, связанном с ухудшением здоровья либо последствием каких-либо травм.
           3.2.9. Предоставить МКФ медицинскую справку о состоянии здоровья при начале получения услуг. В противном случае МКФ не несет ответственность за неправильно подобранную для КЛИЕНТА программу.
           3.2.10. Поддерживать внутренний порядок в помещении МКФ, не пользоваться приспособлениями и оборудованием в нем без разрешения инструктора или мастера кунг-фу. Выполнять любые распоряжения администрации на территории МКФ.
           3.2.11. Ознакомиться с техникой безопасности (на стендах или сайте) нахождения на территории МКФ и использования приспособлений, при взаимодействиях во время парной работы, а также о ваших действиях при пожаре и других действиях. Подписание данного договора говорит об ознакомлении с данными правилами.
           3.2.12. Находиться на занятиях в специальной одежде (указанной на сайте) или приобрести её в «Магазине МКФ».
            </p>
            <p className={styles.contentPart}>
           3.3. ОЦ имеет право:
           3.3.1. Предоставлять консультации по действующим программам и тарифам МКФ.
           3.3.2. Временно или полностью отказать КЛИЕНТУ в доступе в МКФ и расторгнуть договор в одностороннем порядке без объяснения причин с возвратом оставшихся внесенных КЛИЕНТОМ средств, согласно п.4.7.
           3.3.3. Изменить время проведения занятий или отменить их полностью в расписании, с предварительным уведомлением об этом на нашем сайте. В случае отмены единовременно сообщить КЛИЕНТАМ, которые были записаны на них, посредством любого мессенджера. При невозможности КЛИЕНТА отработать данные занятия во время действия абонемента, перенести ему все средства от отмененного урока на новый абонемент.
           3.3.4. В случае пропуска занятий КЛИЕНТОМ без информирования МКФ об этом посредством любого нашего мессенджера или при сообщении, но без уважительной причины (документ о болезни или командировки) списать данный урок с абонемента. Если уважительная причина есть, то часть средств переносится на новый абонемент.
           3.3.5. Производить фото- и видеосъемку в месте проведения занятий без предварительного согласия КЛИЕНТА.
           3.3.6. Изменять цены на предоставляемые услуги в одностороннем порядке, размещая новый прайс на сайте.
           3.3.7. Воздействовать на КЛИЕНТА во время получения услуг различными приспособлениями, оборудованием, материалами и телесными касаниями инструктором МКФ по оздоровительной культуре.
           3.3.8. Отключить доступ к любым видеозаписям любой программы, ранее предоставленным КЛИЕНТУ, по собственному усмотрению, не информируя КЛИЕНТА, кроме уроков и программ, выкупленных в «Магазине МКФ».
           3.3.9. Отказать в доступе к онлайн-трансляции любых видов занятий МКФ без объяснения причин КЛИЕНТУ, подавшему заявку.
           3.3.10 Забирать пять минут в начале и конце урока для подготовки помещения к очередным занятиям и проветривания его согласно санитарным нормам и требованиям.
           </p>
           <p className={styles.contentPart}>
           3.4. ОЦ обязан:
           3.4.1. Оказывать КЛИЕНТУ услуги по графику и в объемах, указанным в заявленной на сайте программе, выкупленной им.
           3.4.2. Предоставить КЛИЕНТУ видеоматериалы занятий при условии полной оплаты изучаемой программы.
           3.4.3. Предоставить место для того, чтобы подготовиться к занятиям и надеть специальную одежду, а также уборную и умывальник для гигиенических нужд КЛИЕНТА.
           3.4.4. Информировать КЛИЕНТА, зарегистрированного в наших социальных сетях, обо всем в центре МКФ.
           3.4.5. Предоставить видеоматериалы КЛИЕНТУ, который выкупил курс АКФ, независимо от того, посещал ли он их.
           3.4.6. Соблюдать требования и норма согласно санитарным нормам для проведения физкультурно-оздоровительной деятельности.
           </p>

           <p className={styles.contentHeader}>
          4. СТОИМОСТЬ ФИЗКУЛЬТУРНО-ОЗДОРОВИТЕЛЬНЫХ УСЛУГ И ПОРЯДОК РАСЧЕТОВ
             </p>

           <p className={styles.contentPart}>
           4.1. Стоимость абонемента физкультурно-оздоровительных услуг формируется на основании п. 2.3. Приказа Министерства Спорта и Туризма РБ за № 832 от 30.06.1997 г. и определяется в соответствии с Правилами приобретения, изменения, переноса и закрытия абонемента, действующими на момент приобретения абонемента.
           4.2. КЛИЕНТ оплачивает стоимость услуг путем внесения наличных денежных средств в кассу МКФ или на расчетный счет ООО «Хонг-Гиа».
           4.3. Форма оплаты - 100% предоплата, если не оговорены иные формы оплаты по согласованию сторон дополнительным соглашением.
           4.4. В случае посещения КЛИЕНТОМ большего количества занятий, чем было заявлено, необходимо произвести перерасчет по тарифу выкупленного абонемента.
           4.5. Система скидок, акций и льгот описана на сайте МКФ. Скидки и акции не суммируются.
           4.6. В случае, когда КЛИЕНТ не присутствовал на проведенной тренировке по уважительной причине, которой являются болезни, командировки за пределы города (с предоставлением соответствующих документов), то полученные средства МКФ могут быть перенесены на новый абонемент, согласно «Правил приобретения, изменения, переноса и закрытия абонемента», описанных в Приложении к данному договору, выставленному на нашем официальном сайте.
           4.7. Возврат денежных средств производится только в случае прекращения посещения КЛИЕНТА МКФ за не посещённые занятия (не полученные услуги) согласно «Правил приобретения, изменения, переноса и закрытия абонемента», описанных в Приложении к данному договору, выставленному на нашем официальном сайте.
           4.8. В случае невозможности предоставления услуги КЛИЕНТУ по причине закрытия МКФ возврат денежных средств за неиспользованные посещения производится в течении 90 (девяноста) дней после АКТА сдачи помещения.
           </p>

           <p className={styles.contentHeader}>
          5. ПРИОСТАНОВЛЕНИЕ И ДОСРОЧНОЕ РАСТОРЖЕНИЕ ДОГОВОРА
          </p>

           <p className={styles.contentPart}>
          5.1. Договор подлежит досрочному расторжению в одностороннем порядке по инициативе ОЦ в следующих случаях:
          5.1.1. Нарушения КЛИЕНТОМ Правил МКФ или условий настоящего Договора.
          5.1.2. Нарушения КЛИЕНТОМ порядка оплаты абонемента.
          5.1.3. Действий, понесших различные повреждения имущества центра, а также любого физического воздействия на других людей как на территории центра, так и вне, которое руководство МКФ посчитает несанкционированным или агрессивным.
          5.1.4. Выявления у КЛИЕНТА документально подтвержденных противопоказаний, создающих угрозу его жизни или жизни и здоровью иных КЛИЕНТОВ либо персонала МКФ.
          5.1.5. Нахождения КЛИЕНТА на территории МКФ в состоянии алкогольного или наркотического воздействия.
          5.2. При нарушении КЛИЕНТОМ п. 5.1. настоящего Договора, возврат денежных средств не производится.
          5.3. В случае закрытия МКФ, согласно пункта 4.8.
           </p>

           <p className={styles.contentHeader}>
           6. СРОК ДЕЙСТВИЯ ДОГОВОРА
           </p>

           <p className={styles.contentPart}>
          6.1. Настоящий договор вступает в силу с момента приобретения КЛИЕНТОМ абонемента в МКФ и действует бессрочно.
           </p>
           <p className={styles.contentHeader}>
           7. ОСОБЫЕ УСЛОВИЯ ДОГОВОРА
           </p>


           <p className={styles.contentPart}>
           Вы можете использовать некоторые аспекты Услуг в любое время без предоставления какой-либо личной информации. Хотя предоставление личной информации является добровольным, вы не сможете воспользоваться некоторыми Услугами или участвовать в них, если решите не делиться ими.
           </p>
           <p className={styles.contentPart}>
           7.1. Все изменения, дополнения, приложения, направленные на оптимизацию договорных отношений между сторонами, являются неотъемлемыми частями Договора.
           7.2. КЛИЕНТ полностью несет ответственность за состояние своего здоровья в случае несоблюдения правил или распоряжений работников МКФ.
           7.3. Подписание данного Договора говорит о том, что КЛИЕНТ ознакомлен со всеми правилами нахождения на территории МКФ и техникой безопасности при получении услуги. Все сотрудники МКФ не несут ответственность за ухудшение здоровья КЛИЕНТА или травмы, полученные в результате любых занятий в случае невыполнения требований, правил и инструкций по нахождению на территории центра и выполнению техник и различных заданий, а также в случае любого телесного воздействия на КЛИЕНТА. С этой целью ведется видео съемка в месте проведения занятий.
           7.4. Принимая условия настоящего Договора, КЛИЕНТ соглашается с тем, что он не вправе требовать от МКФ какой-либо компенсации морального и/или материального вреда, причиненного его здоровью как в течение срока действия настоящего Договора, так и по истечении срока его действия, за исключением случаев, прямо предусмотренных законодательством РБ.
           7.5. МКФ оставляет за собой право временно приостанавливать работу по техническим причинам, в случае переезда на новое место расположения МКФ или иным причинам. В таком случае, срок действия абонемента продлевается на время вынужденного простоя в работе МКФ.
           7.6. Информация считается доведенной до сведения КЛИЕНТА, если она размещена на сайте www.kung-fu.world.
           7.7. МКФ оставляет за собой право на одностороннее изменение настоящего Договора. В случае изменения новая редакция Договора и Правил будет размещена на веб-сайте и информационном стенде МКФ.
           </p>

           <p className={styles.contentHeader}>
          8. ФОРС-МАЖОР
           </p>


           <p className={styles.contentPart}>
          8.1. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по настоящему Договору, если это неисполнение явилось следствием обстоятельств непреодолимой силы, возникших после заключения настоящего Договора в результате обстоятельств чрезвычайного характера, таких как: наводнение, пожар, землетрясение и другие природные явления, а также война, военные действия, блокада, запретительные действия властей и акты государственных органов, разрушение коммуникаций и энергоснабжения, взрывы, возникшие во время действия настоящего договора, которые стороны не могли предвидеть или предотвратить.
          8.2. Все споры или разногласия, возникающие между сторонами по настоящему Договору или в связи с ним, разрешаются путем переговоров. Все отношения, не урегулированные сторонами в настоящем Договоре, регламентируются действующим законодательством Республики Беларусь.
           </p>

           <p className={styles.contentHeader}>
          9. АДРЕСА, БАНКОВСКИЕ РЕКВИЗИТЫ
           </p>
           <p className={styles.contentPart}>
          Реквизиты ОЦ:
          ООО «Хонг-Гиа» УНП 191210352,
          ОКПО 378641495000,
          г. Минск, ул.М.Богдановича, 138, пом.296,
          тел. (044) 518-00-00, www.kung-fu.by
          Р/с. ЗАО "Альфа-Банк", г.Минск
          BY66ALFA 3012 2063 8600 8027 0000 код ALFABY2Х


           </p>
         <p className={styles.contentPart}>
          Директор
                   Безрукавый Е.В.
            </p>

           </div>

        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>

          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>

      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;

const Content = styled.div`
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;

const BtnWrapper = styled.div`
  max-width: 390px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


