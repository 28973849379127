import * as React from "react";
import Master from "../../assets/img/mc_hrz_rev.png";

function SvgComponent(props) {
  return (
     <img src={Master} alt="master" className="paymentLogo" />
  );
}

export default SvgComponent;
