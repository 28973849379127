import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { GrowthBookProvider, GrowthBook } from "@growthbook/growthbook-react";

import amplitude from 'amplitude-js';

import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import strings from "./assets/localization/strings";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import Policy from "./screens/Policy.jsx";
import Privacy from "./screens/Privacy.jsx";
import Agreements from "./screens/Agreements.jsx";
import Abonements from "./screens/Abonements.jsx";
import Services from "./screens/Services.jsx";
import ScrollToTop from "./utils/ScrollToTop";

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: "sdk-tbi5NRjBnDAf7aK",
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key
    });
    }
});


function App() {

    const amplitudeOnInit = (instance) => {

    };

    amplitude.getInstance().onInit(amplitudeOnInit);
    const initializeAmplitude = amplitude.getInstance().init('20b2ef279e737dc97782bd978021a7a7');
    if(localStorage.getItem('user_id') !== null && localStorage.getItem('user_id') !== undefined ){
        amplitude.getInstance().setUserId(localStorage.getItem('user_id'));
    } else{
        const v4Id = uuidv4();
        localStorage.setItem('user_id', v4Id);
        amplitude.getInstance().setUserId(v4Id);
    }

    useEffect(() => {


        //amplitude.getInstance().onInit(amplitudeOnInit);
        //amplitude.getInstance().setUserProperties(userProperties);

        growthbook.loadFeatures();
        growthbook.setAttributes({
            "id": new Date(),
            "loggedIn": true,
            "employee": true
        });
    }, []);

//    let l = window.location;
//    let pathValue;
//    if(l.hash !== null && l.hash !== undefined && l.hash !== ""){
//        let array = l.pathname.split('/');
//        pathValue = array.slice(1, array.length + 1)[0];
//    }
//     console.log("pathValue: " + pathValue)

    return (
               <>
               <div>
              <ScrollToTop />
              <Routes>
                 <Route path="/" element={  <Landing /> } />
                 <Route path="/terms" element={ <Policy /> }/>
                 <Route path="/privacy" element={ <Privacy /> }/>
                 <Route path="/services" element={ <Services /> }/>
                 <Route path="/agreements" element={ <Agreements />}/>
                 <Route path="/abonements" element={ <Abonements />}/>
              </Routes>
                 </div>
               </>

             );
   }

export default App;
