import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import styles from "./Policy.module.scss";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ServicesContent() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font40">Условия подписки</h1>
          <HeaderP className="font13 semiBold">
             Дата последнего обновления: 10 июля 2024 г.
          </HeaderP>

           <div className={styles.contentContainer}>

           <p className={styles.contentHeader}>
            1. Бесплатная или платная пробная версия.
           </p>
           <p className={styles.contentPart}>
           Мы можем предложить бесплатную или платную (за небольшую плату) пробную подписку на эту услугу. Если вы не отмените подписку до окончания пробного периода, с вас будет автоматически снята сумма, указанная на экране оплаты и/или всплывающем окне оплаты Apple/Google/веб-сайтов за выбранный период подписки. Обратите внимание: если предлагается пробная версия, это будет явно указано на экране цен перед оформлением заказа. Если это не так, вы приобретете нашу подписку без пробной версии. Мы также можем время от времени делать предложения со скидкой, которые продлеваются по полной цене без скидки, а также другие предложения, которые могут быть вам интересны.
           </p>

            <p className={styles.contentHeader}>
            2. Подписка.
             </p>

          <p className={styles.contentPart}>
          Подписка продлевается автоматически в конце каждого периода (каждую неделю, месяц, 6 месяцев, год или иным образом, в зависимости от варианта, выбранного вами во время покупки), пока вы не отмените ее.
          </p>

           <p className={styles.contentHeader}>
           3. Способ оплаты.
           </p>

           <p className={styles.contentHeader}>
           Оплата будет снята с помощью способа оплаты, который вы указали во время покупки при подтверждении покупки (после того, как вы подтвердите это с помощью идентификации одним касанием, распознавания лица или ввода данных о способе оплаты в Интернете или иного принятия условий подписки, представленных на веб-сайте) экране оплаты или на всплывающем экране Apple/Google или на нашей веб-странице) или после окончания пробного периода. Вы разрешаете нам взимать соответствующую абонентскую плату с используемого вами способа оплаты.
           </p>

           <p className={styles.contentPart}>
          Нажимая [ПРОДОЛЖИТЬ] на веб-странице, вы разрешаете Hong-Gia списать с указанного выше банковского счета любую сумму, причитающуюся за расходы, возникающие в результате использования вами услуг Hong-Gia и/или покупки продуктов у Hong-Gia в соответствии с ценой на веб-сайте и условиями Hong-Gia, пока это разрешение не будет отозвано. Вы можете изменить или отменить это разрешение в любое время, уведомив Hong-Gia за 30 (тридцать) дней.
          </p>

            <p className={styles.contentPart}>
           Если вы пользуетесь услугами Hong-Gia или периодически приобретаете дополнительные продукты в соответствии с условиями Hong-Gia, вы разрешаете Hong-Gia периодически дебетовать свой банковский счет. Платежи, выходящие за рамки обычного дебетования, разрешенного выше, будут списаны только после получения вашего разрешения.
           </p>

            <p className={styles.contentHeader}>
            4. Отмена.
           </p>

           <p className={styles.contentPart}>
            Ваша подписка продлевается автоматически в конце каждого периода, пока вы не отмените ее. Обратите внимание, что удаление приложения не отменяет ваши подписки.
           </p>

           <p className={styles.contentPart}>
           Если вы приобрели подписку или включили пробную версию в App Store: вы можете отменить бесплатную пробную версию или подписку в любое время, отключив автоматическое продление в настройках учетной записи Apple ID. Чтобы избежать списания средств, отмените подписку в настройках учетной записи Apple ID не менее чем за 24 часа до окончания бесплатной пробной версии или текущего периода подписки. Вы сами можете управлять своими подписками. Узнайте больше об управлении подписками (и о том, как их отменить) на странице поддержки Apple.
             </p>

           <p className={styles.contentPart}>
          Если вы приобрели подписку или включили пробную версию в Google Play: вы можете отменить бесплатную пробную версию или подписку в любое время, отключив автоматическое продление в настройках своей учетной записи Google Play. Чтобы избежать списания средств, отмените подписку в настройках своей учетной записи как минимум за 24 часа до окончания пробного периода или текущего периода подписки. Вы сами можете управлять своими подписками. Узнайте больше об управлении подписками (и о том, как их отменить) на странице поддержки Google.
          </p>

           <p className={styles.contentPart}>
           Если вы приобрели подписку или включили пробную версию на наших веб-сайтах: во избежание оплаты отмените подписку до окончания текущего периода. Вы можете отменить подписку, приобретенную на нашем сайте, написав в нашу службу поддержки по адресу admin@hong-gia.com или заполнив контактную форму.
           </p>
           <p className={styles.contentPart}>
           Отмена подписки означает, что автоматическое продление будет отключено, но у вас по-прежнему будет доступ ко всем функциям подписки в течение оставшегося времени текущего периода.
            </p>

           <p className={styles.contentHeader}>
           5. Возврат средств.
           </p>

           <p className={styles.contentPart}>
          На подписки, приобретенные через App Store/Google Play, распространяются правила возврата средств этого магазина. Это означает, что мы не можем предоставить возмещение. Вам придется обратиться в службу поддержки Магазина.
          </p>

           <p className={styles.contentPart}>
          Если вы приобрели подписку или включили пробную версию в App Store: если вы имеете право на возврат средств, вам придется запросить его непосредственно у Apple. Чтобы запросить возврат средств, следуйте этим инструкциям на странице поддержки Apple.
          </p>

           <p className={styles.contentPart}>
          Если вы приобрели подписку или включили пробную версию в Google Play: если вы имеете право на возврат средств, вам придется запросить его непосредственно у Google. Чтобы запросить возврат средств, следуйте этим инструкциям на странице поддержки Google.
           </p>

           <p className={styles.contentPart}>
          Если вы приобрели подписку или включили пробную версию на наших веб-сайтах, вы соглашаетесь с тем, что Покупка является окончательной, что Hong-Gia не возмещает никакую транзакцию после ее совершения и что Покупку нельзя отменить. Совершая Покупку, вы признаете и соглашаетесь с тем, что все Покупки не подлежат возврату или обмену. Невзирая на какие-либо противоречащие положениям вышеизложенного, Hong-Gia обеспечит возврат средств и/или отмену Покупки в случаях и в той мере, в какой это требуется обязательными положениями применимого законодательства. Hong-Gia также может осуществлять возврат средств по своему усмотрению и в соответствии с нашей политикой, которые могут публиковаться время от времени.
           </p>

           <p className={styles.contentPart}>
           Обратите внимание, что после истечения срока вашей подписки мы не сможем вернуть вам деньги, поскольку услуга будет считаться использованной в полном объеме, если иное не предусмотрено действующим законодательством.
           </p>
           <p className={styles.contentHeader}>
            Примечание для жителей ЕС
             </p>


           <p className={styles.contentPart}>
          Если вы являетесь пользователем ЕС, вы имеете право отказаться от договора на приобретение цифрового контента без взимания платы и объяснения причин в течение четырнадцати (14) дней с даты заключения такого договора. Право на отказ не применяется, если исполнение соглашения началось с вашего предварительного явного согласия и признания того, что вы тем самым теряете право на отказ. НАСТОЯЩИМ ВЫ ЯВНО СОГЛАШАЕТЕСЬ НА НЕМЕДЛЕННОЕ ИСПОЛНЕНИЕ СОГЛАШЕНИЯ И ПОДТВЕРЖДАЕТЕ, ЧТО ВЫ ПОТЕРЯЕТЕ СВОЕ ПРАВО НА ВЫХОД ИЗ СОГЛАШЕНИЯ, КАК НАШИ СЕРВЕРЫ ПОДТВЕРЖДАЮТ ВАШУ ПОКУПКУ И СООТВЕТСТВУЮЩАЯ ПОКУПКА БУДЕТ УСПЕШНО ДОСТАВЛЕНА ВАМ. Таким образом, вы не будете иметь право на возмещение, если только цифровой контент не окажется дефектным.
          </p>
           <p className={styles.contentHeader}>
           6. Изменения.
            </p>

           <p className={styles.contentPart}>
           В максимальной степени, разрешенной действующим законодательством, мы можем изменить стоимость подписки в любое время. Мы заблаговременно уведомим вас о любых таких изменениях цен, опубликовав новые цены в приложении или через него и/или отправив вам уведомление по электронной почте или другими заметными способами. Если вы не хотите платить новые сборы, вы можете отменить соответствующую подписку до вступления изменений в силу.
           </p>


           <p className={styles.contentPart}>
           Если у вас есть какие-либо вопросы относительно условий подписки, свяжитесь с нами через чат или напрямую по адресу admin@hong-gia.com.
           </p>

           <p className={styles.contentPart}>
          Пожалуйста, сделайте скриншот этой информации для справки. Это может помочь вам контролировать свои подписки.
           </p>

           </div>

        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>

          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>

      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;

const Content = styled.div`
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;

const BtnWrapper = styled.div`
  max-width: 390px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


