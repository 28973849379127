import * as React from "react";
import ApplePay from "../../assets/img/Apple_Pay_Mark_RGB_041619.png";

function SvgComponent(props) {
  return (
     <img src={ApplePay} alt="apple pay" className="paymentLogo" />
  );
}

export default SvgComponent;
