import * as React from "react";
import MasterIDC from "../../assets/img/mc_idcheck_vrt_rev.png";

function SvgComponent(props) {
  return (
     <img src={MasterIDC} alt="master idcheck" className="paymentLogo" />
  );
}

export default SvgComponent;
