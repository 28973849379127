import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";
import Alfa from "../../assets/svg/Alfa";
import Apple from "../../assets/svg/Apple";
import Belkart from "../../assets/svg/Belkart";
import BelkartInter from "../../assets/svg/BelkartInter";
import Master from "../../assets/svg/Master";
import MasterIDC from "../../assets/svg/MasterIDC";
import Samsung from "../../assets/svg/Samsung";
import Visa from "../../assets/svg/Visa";
import VisaSecure from "../../assets/svg/VisaSecure";

import { useNavigate } from "react-router";

import styles from "./Footer.module.scss";

export default function Footer() {
  const navigate = useNavigate();
  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

 const toTerms = () => {

        navigate("/terms")
    }

  const toPrivacy = () => {

         navigate("/privacy")
 }

  const toServices = () => {

          navigate("/services")
  }

   const toAgreements = () => {

           navigate("/agreements")
   }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
             <HeaderInfo>
              <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
                           <LogoImg />
              </Link>
                         <p className="whiteColor font13">
                                     <br />
                                     ООО "Хонг-Гиа"
                                     <br />
                                     Тел:  +375 (44) 518-00-00
                                     <br />
                                     Email: admin@hong-gia.by
                                     <br />
                                     Республика Беларусь, г.Минск,
                                     <br />
                                     ул.М.Богдановича, 138, помещение 296
                                     <br />
                                     220040
                         </p>


             </HeaderInfo>


            <HeaderInfo>
            <StyleP className="whiteColor font13">
              © 1992 - {getCurrentYear()} - <span className="purpleColor font13"></span> "МИР КУНГ-ФУ"
            </StyleP>
            <div className={styles.footerContainer} onClick={toTerms}>
             <p > Публичный договор </p>
            </div>

             <div className={styles.footerContainer} onClick={toServices}>
                  <p >  Условия покупки </p>
             </div>

              <div className={styles.footerContainer} onClick={toPrivacy}>
                    <p >  Политика в отношении обработки персональных данных </p>
              </div>

              <div className={styles.footerContainer} onClick={toAgreements}>
                 <p > Согласие на обработку персональных данных </p>
              </div>

            </HeaderInfo>

            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              К началу
            </Link>

          </InnerWrapper>
            <PaymentLogos>
                                        <Alfa />
                                        <Master />
                                        <MasterIDC />
                                         <Visa />
                                         <VisaSecure />
                                          <Apple/>
                                          <Belkart />
                                            <BelkartInter />
                                             <Samsung />

           </PaymentLogos>

        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  display: grid;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const PaymentLogos = styled.div`
  display: flex;
  padding-bottom: 20px;
  @media (max-width: 860px) {
      flex-direction: column;
      align-items: center;
    }
`;