import * as React from "react";
import AlfaRed from "../../assets/img/Alfa-Bank_logo_red_horiz_rus_RGB.png";

function SvgComponent(props) {
  return (
     <img src={AlfaRed} alt="alfa bank" className="paymentLogo" />
  );
}

export default SvgComponent;
