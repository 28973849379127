import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Это мое место для 'романа с самой собой' на долгое время! Я очень люблю нашу школу. Спасибо Мастеру, что просто поддерживает уважение к собственной личности и даёт возможность ощутить связь тела и души. Спасибо, Мастер, я буду стараться работать над собой!"
            author="Майя Б."
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Всё очень хорошо. Минимальное что я могу сказать, это то что здесь преподают, пожалуй лучшую спортивно оздоровительная программу из всего что есть в Минске."
            author="Айдар"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Всем советую посетить это лучшее место, для оздоровления, релаксации и мудрости."
            author="Алена Максимова"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Тут себя найдет каждый"
            author="Алексей Ш."
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Был на индивидуальных практиках в мире кунг-фу, всё очень понравилось, знания мастера очень обширны, он великолепный собеседник и человек с которым можно говорить практически обо всём, и получишь самый лучший из возможных ответов."
            author="Артур Кравчук"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Кто не пробовал - рекомендую, интересный опыт!"
            author="Виталий"
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
