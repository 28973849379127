import * as React from "react";
import LogoGold from "../../assets/img/logo_gold.png";

function SvgComponent(props) {
  return (
     <img src={LogoGold} alt="mir kung fu" className="logo" />
  );
}

export default SvgComponent;
