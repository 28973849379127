import * as React from "react";
import Samsung from "../../assets/img/SamsungPay_RGB_BLK_Horizontal.png";

function SvgComponent(props) {
  return (
     <img src={Samsung} alt="samsung" className="paymentLogo" />
  );
}

export default SvgComponent;
