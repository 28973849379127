import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Запись на семинары</h1>
            <p className="font13">
             <br />
              Факультативные Курсы - это разработанные нами программы,
               <br />
              которые позволяют быстро изучать программы различных факультетов Академии.
              <br />
              Материал в них дается кратко и компактно, чтобы человек мог быстро обучиться.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price="680 р."
                title="Тай-Чи: 12 движений Воды"
                text="Техника Тай-Чи дает вам возможность восстановить энергетические потоки Воды, Земли и Неба в теле."
                offers={[
                  { name: "Для начинающих", cheked: true },
                  { name: "Группы онлайн и оффлайн", cheked: true },
                  { name: "Видеозапись уроков", cheked: true },
                  { name: "Техники самообороны", cheked: true },
                  { name: "Оздоровление", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price="680 р."
                title="Нга-Ми: 12 движений Огня"
                text="это оздоровительная внутренняя техника, основанная на работе бедрами, как у танцующей девушки."
                offers={[
                  { name: "Женское Кунг-фу", cheked: true },
                  { name: "Техники самообороны", cheked: true },
                  { name: "Видеозапись уроков", cheked: true },
                  { name: "Альтернатива фитнесу", cheked: true },
                  { name: "Не нужен инвентарь", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="680 р."
                title="Медитация Трех стихий"
                text="Благодаря медитации улучшается работа мозга, останавливается старение тела, человек не входит в депрессию, у него уходят такие состояния как: стресс, страх, испуг, панические атаки и т.д."
                offers={[
                  { name: "Для любого уровня подготовки", cheked: true },
                  { name: "Видеозапись уроков", cheked: true },
                  { name: "Группы онлайн и офлайн", cheked: true },
                  { name: "Совершенствование личности", cheked: true },
                  { name: "Духовное развитие", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




