import * as React from "react";
import BelkartInter from "../../assets/img/belkart_internetparol.png";

function SvgComponent(props) {
  return (
     <img src={BelkartInter} alt="belkart internetparol" className="paymentLogo" />
  );
}

export default SvgComponent;
