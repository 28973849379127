import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Для чего вам нужно Кунг-Фу?</h1>
            <p className="font13">
               <br />
              Кунг - Фу дословно переводится как «работа» и "время".

              Чем бы мы ни занимались, мы постоянно находимся в состоянии совершенствования своего Кунг-Фу.


              <br />
               Мастером Кунг-Фу называют человека, достигшего высочайшего уровня в избранной деятельности, независимо от ее области.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Ребенок "
                text="внимательность, умение концентрироваться,
                      способность воплощать свои идеи, уверенность в себе,
                      лидерские качества,целеустремленность,
                      собранность и повышение успеваемости"
                tag="4-9 лет"
                author="Развивает:"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="ПОДРОСТОК "
                text="видения своего пути, желание познать себя,
                      самостоятельность, уверенность и веру в себе,
                      решительность, стремление к справедливости,
                      храбрость"
                tag="10-20 ЛЕТ"
                author="Развивает:"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="МОЛОДОЙ ЧЕЛОВЕК "
                text="что есть разум и сознание в нем; ценность
                      партнерских отношений; выход в самореализацию;
                      нахождение ответа на вопрос 'КТО Я?';
                      философию жизни и ее ценность"
                tag="21-33 ГОДА"
                author="Осознает:"
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="ВЗРОСЛЫЙ ЧЕЛОВЕК "
                text="свое время и пространство, ценности бытия,
                      самосовершенствованиe, видение и осмысление будущего,
                      познание истинной любви, радости и счастья,
                      философии своего пути"
                tag="34-50 ЛЕТ"
                author="Создает и формирует:"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="МУДРЫЙ ЧЕЛОВЕК"
                text="неограниченные возможности в реализации, открытие всего мира для себя;
                      духовное просветление, понимание мироздания, реализация цели жизни"
                tag=" 50-70 ЛЕТ"
                author="Достиг:"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Духовный человек "
                text="достигнуть самореализации, полное освобождение от Эго,
                      и чем помочь стране и планете, личный опыт и знаний,
                      поддерживать членов рода,передавать свой труд для потомков"
                tag="72-108 ЛЕТ"
                author="Передал другим как и зачем:"
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Начать свой путь" action={() => alert("clicked")} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Отзывы о нас</h1>
            <p className="font13">

              <br />
             Ждем вас в нашем "Мир Кунг-фу" 🙏🏻
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;