import * as React from "react";
import VisaSecure from "../../assets/img/visa-secure_blu_RGB.png";

function SvgComponent(props) {
  return (
     <img src={VisaSecure} alt="visa secure" className="paymentLogo" />
  );
}

export default SvgComponent;
