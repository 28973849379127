import * as React from "react";
import Belkart from "../../assets/img/belkart.png";

function SvgComponent(props) {
  return (
     <img src={Belkart} alt="belkart" className="paymentLogo" />
  );
}

export default SvgComponent;
