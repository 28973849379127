import * as React from "react";
import Visa from "../../assets/img/vbm_wht01_rgb.png";

function SvgComponent(props) {
  return (
     <img src={Visa} alt="visa" className="paymentLogo" />
  );
}

export default SvgComponent;
