import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg6 from "../../assets/img/projects/6.png";
import AddImage2 from "../../assets/img/add/add2.png";
import { useNavigate } from "react-router";

export default function Projects() {
const navigate = useNavigate();
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Направления</h1>
            <p className="font13">
               <br />
              "Мир Кунг-Фу" предлагает программы по следующим направлениям:
              <br />
             - "Оздоровление";
              <br />
            - "Детское развитие" (для детей 4-9 лет);
              <br />
            - "Школа" (для всех с 10 лет);
               <br />
            - "Академия" (углубленное изучение техник, необходимо поступать после школы)
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Цигун"
                text="Цигун HG или оздоровительная восточная гимнастика, в нашем центре Мир Кунг-фу, использует программу школы 'Нong-Gia' в сочетании с китайской программой 16 века. Во время обучения мы познакомим вас с различными техниками и научим их повседневно применять для сохранения здоровья и долголетия. Вы прикоснетесь к тайнам Востока, и узнаете, как можно поправить себе здоровье и укрепить тело самостоятельно, не используя приспособления и препараты."
                action={() =>  navigate("/terms")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Кунг-Фу для детей"
                text="'Воин Дракона' - это программа для детей (с 4,5 до 10 лет), направленная на регулирование их здоровья, как психического, так и физического, приобретение навыков самообороны, развитие творческих способностей и физических качеств, обучение культуре поведения и самореализации личности. Для этого мы используем древние даосские программы и современные научные разработки, соединяем все лучшее востока и запада. Инструктора имею физкультурное образование и многолетий опыт работы. Руководитель центра, мастер Шао - профессор Даосских техник, со стажем преподования более 33 лет."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Школа Кунг-Фу"
                text="Школа кунг-фу У-Синь - это программа по связям между пятью элементами, и способы применения их в жизни. Философия у-синь и техника у-синь является, основой для всех остальных факультетов .

                      Она состоит из трех образовательных разделов школа, академия, аспирантура. Школа У-синь относится к технике управления стихией Земля."
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Академия Кунг-Фу"
                text="Образовательная программа по подготовке мастеров Hong-Gia - Древние знания. АКАДЕМИЯ состоит из нескольких факультетов каждый из которых 5 курсов.

                      Факультет СТИХИИ ЗЕМЛЯ это программа от синего пояса 2 кап по красный пояс 2 дан с присвоение звания Мастер Даосских Техник и правом открытие своих классов. На данный факультет поступают инструктора по У-синь и Нга-ми после окончания данных школ.

                      Факультет ТРЕХ СТИХИЙ (вода, огонь, воздух) - это программа по подготовке мастеров внутренних техник которые специализируется, на работе с энергиями и миром духов, не традиционной медицине и изотермических знаниях. На даном факультете изучаются техники по управлению стихиями огонь, вода и воздух."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="Аспирантура Кунг-Фу"
                text="Образовательная программа из 5 курсов с красного пояса 3 дан до белого пояса Луна, Солнце, Звезда с присвоением звания Профессор Даосских техник и международным сертификатом от мирового центра Кунг-Фу. Так же как академия имеет два факультета: Факультет СТИХИИ ЗЕМЛЯ и Факультет СТИХИИ ЭФИР"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Факультативные Курсы"
                text="Факультативные Курсы - это разработанные нами программы, которые позволяют быстро изучать программы различных факультетов Академии. Материал в них дается кратко и компактно, чтобы человек мог быстро обучиться. "
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Узнать больше" action={() => alert("clicked")} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>

              <h2 className="font40 extraBold">С чего начать?</h2>
              <h4 className="font15 semiBold">путь в Кунг-Фу</h4>
              <p className="font12">
                ☯︎ ПЕРВЫЙ ШАГ – прийти на пробное занятие (в сентябре на него акция), выбрав удобные дату и время у нас в расписании, заранее записавшись у администратора.
                Так, вы сможете познакомиться с нами поближе, прочувствовать атмосферу занятий и пообщаться с мастером или инструктором, которые смогут направить вас, подобрать вам группу и ответить на возникшие вопросы.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Запись" action={() => navigate("/abonements")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Расписание" action={() => alert("clicked")} border />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
