import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import PolicyContent from "../components/Sections/PolicyContent";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import PublicContract from "../components/Sections/PublicContract"
export default function Policy() {
  return (
    <>
      <TopNavbar />
      <PublicContract />
      <Footer />
    </>
  );
}


