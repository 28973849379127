import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Наши направления</h1>
            <p className="font13">
             <br />
              В нашем центре "Мир кунг-фу" (МКФ) достаточно много программ, и поэтому, чтобы выбрать комфортную для вас,
              <br />
               мы рекомендуем прийти на пробный урок в класс Школы кунг-фу «Пять стихий».
              <br />
              После урока инструктор посоветует вам программу и группу, исходя из ваших пожеланий и цели посещения.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title=" Групповые занятия"
                subtitle="Групповые занятия очень популярны, так как это один из способов взаимодействия с другими людьми. Помимо этого групповые занятия более динамичны и общение между студентами способствует лучшему усвоению материала. У нас можно посещать групповые занятия по таким программам, как Оздоровление, Детское развитие, Школа Кунг-Фу"
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Персональные занятия"
                subtitle="это отдельная услуга в нашем центре по запросу клиента. Форма обучения может быть онлайн и офлайн. Программа подбирается совместно в зависимости от запросов."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Подготовка инструкторов"
                subtitle="В нашем центре можно пройти подготовку на инструктора по даосским оздоровительным техникам. Форма обучения - персональная. Заявку можно оставить через форму обратной связи внизу страницы. При такой форме обучения студент не только приобретает углубленные знания, но и прорабатывает свое тело, решая свои вопросы со специалистом. Персональные занятия записываются на видео.
                          На курсы инструкторов мы приглашаем тех, кто имеет высшее физкультурное образование."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="printer" title="Заказ фирменной атрибутики"
              subtitle="Наш бренд 'Мир кунг-фу' - это центр по изучению древних оздоровительных даосских практик, существующий как юридическое лицо в РБ с июня 2009 года. За это время мы создали свой логотип с фирменным изображением и цветовой формулой. Сегодня можно приобрести следующую нашу продукцию с фирменным логотипом: футболка, футболка-поло, кепка, кружка, одежда для занятий (кимоно/и-фу), значки и т.д. Спрашивайте у администратора о наличии." />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
{/*         <div className="lightBg"> */}
{/*           <div className="container"> */}
{/*             <Advertising className="flexSpaceCenter"> */}
{/*               <AddLeft> */}
{/*                 <h4 className="font15 semiBold">Онлайн-обучение </h4> */}
{/*                 <h2 className="font40 extraBold">Приложение Академия Кунг-Фу</h2> */}
{/*                 <p className="font12"> */}
{/*                   Мы разработали для Вас приложение "Академия Мир Кунг-фу" (kung-fu@world) которое в данный момент находится в тестовом режиме и не доступна для пользователей. Данное приложение имеет все что необходимо для онлайн обучения одному или в группах, обучение уроками которые в находятся в записи по различным темам в плоть до уровня мастер кунг-фу. */}
{/*                 </p> */}
{/*                 <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}> */}
{/*                   <div style={{ width: "190px" }}> */}
{/*                     <FullButton title="Скачать" action={() => alert("clicked")} /> */}
{/*                   </div> */}
{/*                   <div style={{ width: "190px", marginLeft: "15px" }}> */}
{/*                     <FullButton title="Подробнее" action={() => alert("clicked")} border /> */}
{/*                   </div> */}
{/*                 </ButtonsRow> */}
{/*               </AddLeft> */}
{/*               <AddRight> */}
{/*                 <AddRightInner> */}
{/*                   <div className="flexNullCenter"> */}
{/*                     <AddImgWrapp1 className="flexCenter"> */}
{/*                       <img src={AddImage1} alt="office" /> */}
{/*                     </AddImgWrapp1> */}
{/*                     <AddImgWrapp2> */}
{/*                       <img src={AddImage2} alt="office" /> */}
{/*                     </AddImgWrapp2> */}
{/*                   </div> */}
{/*                   <div className="flexNullCenter"> */}
{/*                     <AddImgWrapp3> */}
{/*                       <img src={AddImage3} alt="office" /> */}
{/*                     </AddImgWrapp3> */}
{/*                     <AddImgWrapp4> */}
{/*                       <img src={AddImage4} alt="office" /> */}
{/*                     </AddImgWrapp4> */}
{/*                   </div> */}
{/*                 </AddRightInner> */}
{/*               </AddRight> */}
{/*             </Advertising> */}
{/*           </div> */}
{/*         </div> */}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;